<template>
    <modal-page-template title="Connexion"
                         message="Authentifiez vous pour accéder à toutes les fonctionnalités du site.">
        <login/>
    </modal-page-template>
</template>

<script>
    const ModalPageTemplate = () => import('@/components/templates/ModalPageTemplate');
    const Login             = () => import('@/components/Login');

    export default {
        name: 'WebLogin',
        components: {Login, ModalPageTemplate}
    };
</script>